import CompleteNavigationSubMenuBlock from '@fragment/shared/CompleteNavigationSubMenuBlock';
import CompleteNavigationItemBlock from '@fragment/shared/CompleteNavigationItemBlock';
import CompleteNavigationNestedSubMenuBlock from '@fragment/shared/CompleteNavigationNestedSubMenuBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeNavigationMenuBlock on MicrositeNavigationMenuBlock {
        id: navigationMenuKey
        navigationMenuName
        backgroundColor
        navMenuItems {
            ...CompleteNavigationSubMenuBlock
            ...CompleteNavigationItemBlock
            ...CompleteNavigationNestedSubMenuBlock
        }
    }

    ${CompleteNavigationSubMenuBlock}
    ${CompleteNavigationItemBlock}
    ${CompleteNavigationNestedSubMenuBlock}
`;
