import CompleteMicrositeFooterCTABlock from '@fragment/shared/CompleteMicrositeFooterCTABlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeFooterColumnBlock on MicrositeFooterColumnBlock {
        details
        footerCta {
            ...CompleteMicrositeFooterCTABlock
        }
    }

    ${CompleteMicrositeFooterCTABlock}
`;
