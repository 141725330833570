import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeHeaderBlock on MicrositeHeaderBlock {
        title
        dek
        backgroundImage {
            ...CompleteCustomImage
        }
        backgroundColor
        addTail
    }

    ${CompleteCustomImage}
`;
