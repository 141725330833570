import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeBannerBlock on MicrositeBannerBlock {
        title
        backgroundColor
        isVisible
        url {
            ...CompleteLinkBlock
        }
    }

    ${CompleteLinkBlock}
`;

/* something is up with the link block thing. might need to ask fred? */
