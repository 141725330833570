import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import DefaultBlocks from '@fragment/shared/DefaultBlocks';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteOneColumnBaseTier on OneColumnBaseTier {
        id
        blockType
        blockFormat
        highlight
        backgroundImage {
            ...CompleteCustomImage
        }
        verticalAlignment
        centerColumn {
            ...DefaultBlocks
        }
    }
    ${CompleteCustomImage}
    ${DefaultBlocks}
`;
